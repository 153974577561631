import React, { useEffect, useState, useCallback } from 'react';
import {
  LinearProgress,
  Typography,
  Button,
  Box,
  Grid,
  Divider,
  Card,
  CardContent,
  CardActions
} from '@mui/joy';
import { useNavigate } from 'react-router-dom';
import { useUser } from '../context/user';
import { Formik, Form } from 'formik';
import FlowchartNode from './FlowchartNode';
import {
  getAllNodes,
  getNextNodesToRender,
  getNextStartingNode,
  initialValues,
  validationSchema
} from '../modules/survey';
import { KeyboardArrowRight, Refresh } from '@mui/icons-material';
import {
  Timestamp,
  getFirestore,
  addDoc,
  collection
} from 'firebase/firestore';
import { useTranslation } from 'react-i18next';
import { cleanFormValues } from '../modules/utils';

const Home = () => {
  const { t } = useTranslation();
  const [nodesToRender, setNodesToRender] = useState();
  const [initialFormValues, setInitialFormValues] = useState({});
  const [allNodes, setAllNodes] = useState({});
  const [processing, setProcessing] = useState(false);
  const [isStartingOver, setIsStartingOver] = useState(false);
  const [allPresentedNodes, setAllPresentedNodes] = useState([]);
  const { user } = useUser();
  const navigate = useNavigate();

  const fetchInitialNodes = useCallback(async () => {
    setIsStartingOver(true);
    const nodes = await getAllNodes();
    setAllNodes(nodes);
    const initialNodes = getNextNodesToRender(
      nodes,
      'first_survey',
      user.netResponses
    );
    setNodesToRender(initialNodes);
    setInitialFormValues(initialValues(initialNodes, user.netResponses));
    setIsStartingOver(false);
  }, [user]);

  useEffect(() => {
    if (nodesToRender) {
      setAllPresentedNodes(prevNodes => [...prevNodes, ...nodesToRender]);
    }
  }, [nodesToRender]);

  useEffect(() => {
    if (user) {
      fetchInitialNodes();
    }
  }, [user, fetchInitialNodes]);

  const handleSubmit = async (values, { setSubmitting, resetForm }) => {
    setProcessing(true);
    const lastNode = nodesToRender[nodesToRender.length - 1];

    if (lastNode.nodeId.startsWith('survey_complete')) {
      if (
        lastNode.nodeId === 'survey_complete_previously_pregnant_and_pregnant'
      ) {
        values.reachedPregnantEndNode = true;
      }

      const cleanedValues = cleanFormValues(values);

      const questionOrder = allPresentedNodes
        .map(node => node.nodeId)
        .filter(nodeId => cleanedValues.hasOwnProperty(nodeId));

      console.log('adding doc... with values', cleanedValues);
      await addDoc(
        collection(getFirestore(), 'participants', user.id, 'responses'),
        {
          userId: user.id,
          ...cleanedValues,
          timestamp: Timestamp.now(),
          _metadata: {
            questionOrder
          }
        }
      );

      resetForm();
      setSubmitting(false);
      navigate('/dashboard/thank-you', {
        state: { message: t(`nodes.${lastNode.nodeId}.text`) }
      });
      return;
    }

    const nextStartingNodeId = getNextStartingNode(
      allNodes,
      lastNode.nodeId,
      values
    );
    if (nextStartingNodeId) {
      const nextNodes = getNextNodesToRender(
        allNodes,
        nextStartingNodeId,
        user.netResponses,
        values
      );
      setInitialFormValues({
        ...values,
        ...initialValues(nextNodes, user.netResponses)
      });
      setNodesToRender(nextNodes);
    }

    setSubmitting(false);
    setProcessing(false);
  };

  if (!nodesToRender) {
    return <LinearProgress />;
  }

  const isLastNode =
    nodesToRender[nodesToRender.length - 1].nodeId.startsWith(
      'survey_complete'
    );

  const surveyMessage = () => {
    if (!nodesToRender || nodesToRender.length < 2) return null;

    if (nodesToRender[1].nodeId === 'had_period_since_last_survey') {
      return t('home.latestSurvey');
    } else if (nodesToRender[1].nodeId === 'height') {
      return t('home.firstSurvey');
    }
    return null;
  };

  const message = surveyMessage();

  return (
    <Grid container justifyContent="center" sx={{ my: 9, mx: 3 }}>
      <Grid xs={12} sm={9} md={6} lg={5}>
        <Card variant="outlined" sx={{ p: 3 }}>
          <Typography level="h2" gutterBottom textAlign="center" sx={{ mb: 3 }}>
            {t('home.welcome', { firstName: user?.firstName })}
          </Typography>
          {message && (
            <Typography level="body-lg" textAlign="center" gutterBottom>
              {message}
            </Typography>
          )}
          <Formik
            initialValues={initialFormValues}
            validationSchema={validationSchema(nodesToRender, t)}
            onSubmit={handleSubmit}
            enableReinitialize
          >
            {({ errors, touched, values }) => (
              <Form>
                <CardContent>
                  {nodesToRender.map(node => (
                    <Box key={node.nodeId}>
                      <FlowchartNode
                        node={node}
                        previousResponse={user.netResponses}
                        currentValues={values}
                      />
                      {errors[node.nodeId] && touched[node.nodeId] && (
                        <Typography level="body-sm" color="danger" mt={1}>
                          {errors[node.nodeId]}
                        </Typography>
                      )}
                      {errors[`${node.nodeId}_other`] &&
                        touched[`${node.nodeId}_other`] && (
                          <Typography level="body-sm" color="danger" mt={1}>
                            {errors[`${node.nodeId}_other`]}
                          </Typography>
                        )}
                    </Box>
                  ))}

                  {isLastNode && (
                    <Box
                      sx={{
                        backgroundColor: 'background.level1',
                        p: 2,
                        borderRadius: 'sm'
                      }}
                    >
                      <Typography
                        level="body-md"
                        textAlign="center"
                        fontWeight="bold"
                        gutterBottom
                      >
                        {t('home.allQuestionsCompleted')}
                      </Typography>
                      <Typography level="body-sm" textAlign="center">
                        {t('home.hitSubmit')}
                      </Typography>
                    </Box>
                  )}
                </CardContent>
                <Divider sx={{ mt: 3 }} />
                <CardActions sx={{ justifyContent: 'space-between' }}>
                  <Button
                    startDecorator={<Refresh />}
                    onClick={fetchInitialNodes}
                    variant="outlined"
                    color="neutral"
                    size="sm"
                    loading={isStartingOver}
                  >
                    {t('home.startOver')}
                  </Button>
                  <Button
                    endDecorator={isLastNode ? null : <KeyboardArrowRight />}
                    loading={processing}
                    type="submit"
                    size="sm"
                  >
                    {isLastNode ? t('home.submitAnswers') : t('home.next')}
                  </Button>
                </CardActions>
              </Form>
            )}
          </Formik>
        </Card>
      </Grid>
    </Grid>
  );
};

export default Home;
