import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/user';
import { collection, query, getDocs, orderBy } from 'firebase/firestore';
import { getFirestore } from 'firebase/firestore';
import { format } from 'date-fns';
import {
  Typography,
  AccordionGroup,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  Sheet,
  Grid
} from '@mui/joy';
import { History } from '@mui/icons-material';

// Import all language JSON files
import englishTranslations from '../locales/english.json';
import vietnameseTranslations from '../locales/vietnamese.json';
import hebrewTranslations from '../locales/hebrew.json';

const SurveyHistory = () => {
  const { t } = useTranslation();
  const { user } = useUser();
  const [responses, setResponses] = useState([]);

  // Get the correct translations based on user language
  const getTranslations = () => {
    switch (user.language) {
      case 'vietnamese':
        return vietnameseTranslations;
      case 'hebrew':
        return hebrewTranslations;
      default:
        return englishTranslations;
    }
  };

  const translations = getTranslations();

  useEffect(() => {
    const fetchResponses = async () => {
      if (user && user.id) {
        const db = getFirestore();
        const responsesRef = collection(
          db,
          'participants',
          user.id,
          'responses'
        );
        const q = query(responsesRef, orderBy('timestamp', 'desc'));
        const querySnapshot = await getDocs(q);
        const fetchedResponses = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setResponses(fetchedResponses);
      }
    };
    fetchResponses();
  }, [user]);

  const formatTimestamp = timestamp => {
    if (timestamp && timestamp.toDate) {
      return format(timestamp.toDate(), "MMMM d, yyyy 'at' h:mm a");
    }
    return 'Unknown date';
  };

  const getTranslatedKey = key => {
    return translations.nodes[key]?.text || key;
  };

  const getTranslatedValue = (key, value) => {
    if (Array.isArray(value)) {
      return value.map(v => getTranslatedValue(key, v)).join(', ');
    }

    if (typeof value === 'boolean') {
      return value ? t('ui.yes') : t('ui.no');
    }

    const options = translations.nodes[key]?.options;
    if (options) {
      const option = options.find(opt => opt.key === value);
      return option ? option.text : value;
    }

    return value;
  };

  return (
    <Grid container justifyContent="center" sx={{ my: 8, mx: 3 }}>
      <Grid xs={12} sm={10} md={8} lg={6}>
        <Typography level="h2" gutterBottom startDecorator={<History />}>
          {t('surveyHistory.title', 'Survey History')}
        </Typography>
        <AccordionGroup>
          {responses.map(response => {
            // Handle both cases - with and without _metadata
            const entries = response._metadata?.questionOrder
              ? // If we have metadata, use the question order
                response._metadata.questionOrder.map(key => [
                  key,
                  response[key]
                ])
              : // Otherwise, just get all entries and filter out the special fields
                Object.entries(response).filter(
                  ([key]) =>
                    key !== 'id' &&
                    key !== 'timestamp' &&
                    key !== 'userId' &&
                    key !== '_metadata'
                );

            return (
              <Accordion key={response.id}>
                <AccordionSummary>
                  {formatTimestamp(response.timestamp)}
                </AccordionSummary>
                <AccordionDetails>
                  <Sheet variant="outlined" sx={{ padding: 2 }}>
                    {entries.map(([key, value]) => {
                      const translatedKey = getTranslatedKey(key);
                      const translatedValue = getTranslatedValue(key, value);
                      return (
                        <Typography
                          key={key}
                          level="body-sm"
                          sx={{
                            mb: 1,
                            wordBreak: 'break-word',
                            '& strong': { mr: 1 }
                          }}
                        >
                          <strong>{translatedKey}</strong>
                          {translatedValue}
                        </Typography>
                      );
                    })}
                  </Sheet>
                </AccordionDetails>
              </Accordion>
            );
          })}
        </AccordionGroup>
      </Grid>
    </Grid>
  );
};

export default SurveyHistory;
