import { useState, useEffect, useCallback } from 'react';
// import i18n from 'i18next';
import {
  Button,
  Alert,
  Stack,
  LinearProgress,
  // Select,
  // Option,
  Typography,
  Input,
  Snackbar,
  Grid,
  FormLabel,
  FormControl,
  Textarea
} from '@mui/joy';
import {
  getAuth,
  verifyBeforeUpdateEmail,
  EmailAuthProvider,
  reauthenticateWithCredential
} from 'firebase/auth';
import { getMessaging, getToken } from 'firebase/messaging';
import { doc, getFirestore, updateDoc, arrayUnion } from 'firebase/firestore';
import { Check, Warning } from '@mui/icons-material';
// import { upperFirstLetterWord } from '../modules/utils';
import { useUser } from '../context/user';
import { useTranslation } from 'react-i18next';

const Settings = () => {
  const { t } = useTranslation();
  const [notificationStatus, setNotificationStatus] = useState('prompt');
  const [isLoading, setIsLoading] = useState(false);
  const [emailVerificationSent, setEmailVerificationSent] = useState(false);
  const { user, userAuth } = useUser();
  const [formData, setFormData] = useState({
    firstName: '',
    lastName: '',
    phoneNumber: '',
    email: ''
  });
  const [processing, setProcessing] = useState(false);
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    color: 'success'
  });

  // const languages = ['english', 'hebrew', 'vietnamese'];

  const updateToken = useCallback(async () => {
    try {
      const messaging = getMessaging();
      let token;
      try {
        token = await getToken(messaging, {
          vapidKey: process.env.REACT_APP_VAPIDKEY
        });
      } catch (error) {
        if (error.code === 'messaging/token-unsubscribe-failed') {
          console.warn(
            'Failed to unsubscribe from FCM, proceeding to get new token'
          );
          token = await getToken(
            messaging,
            {
              vapidKey: process.env.REACT_APP_VAPIDKEY
            },
            true
          );
        } else {
          throw error;
        }
      }

      if (token) {
        await updateDoc(doc(getFirestore(), 'participants', user.id), {
          fcmTokens: arrayUnion(token)
        });
      } else {
        console.warn('No FCM token received');
      }
    } catch (error) {
      console.error('Error getting token or updating Firestore:', error);
      // Instead of immediately setting to 'error', we could implement a retry mechanism
      // or show a user-friendly message suggesting to try again later
    }
  }, [user]);

  const requestPermission = async () => {
    setIsLoading(true);
    try {
      const permission = await Notification.requestPermission();
      setNotificationStatus(permission);
      if (permission === 'granted') {
        await updateToken();
      }
    } catch (error) {
      console.error('Error requesting permission:', error);
      setNotificationStatus('error');
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const checkAndUpdateNotificationStatus = async () => {
      if ('permissions' in navigator && 'Notification' in window) {
        const permission = await navigator.permissions.query({
          name: 'notifications'
        });

        const updateStatus = () => {
          const currentPermission = Notification.permission;
          setNotificationStatus(currentPermission);
          if (currentPermission === 'granted' && user) {
            updateToken();
          }
        };

        updateStatus();
        permission.onchange = updateStatus;

        return () => {
          permission.onchange = null;
        };
      } else {
        setNotificationStatus('unsupported');
      }
    };

    checkAndUpdateNotificationStatus();
  }, [user, updateToken]);

  // const handleLanguageChange = async newValue => {
  //   if (newValue) {
  //     try {
  //       await updateDoc(doc(getFirestore(), 'participants', user.id), {
  //         language: newValue
  //       });
  //       i18n.changeLanguage(newValue);
  //     } catch (error) {
  //       console.error('Error updating language in Firestore:', error);
  //       // Consider showing an error message to the user
  //     }
  //   }
  // };

  useEffect(() => {
    if (user) {
      setFormData({
        firstName: user.firstName || '',
        lastName: user.lastName || '',
        phoneNumber: user.phoneNumber || '',
        email: userAuth.email || '',
        personalNotes: user.personalNotes || ''
      });
    }
  }, [user, userAuth]);

  const handleChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const reauthenticateUser = async () => {
    const password = prompt(
      `To update your email, please re-enter your password.`
    );
    if (password) {
      const credential = EmailAuthProvider.credential(userAuth.email, password);
      await reauthenticateWithCredential(userAuth, credential);
    } else {
      throw new Error('Password not provided');
    }
  };

  const handleSubmit = async e => {
    e.preventDefault();
    setProcessing(true);
    try {
      const auth = getAuth();
      const db = getFirestore();

      // Update email if changed
      if (formData.email !== userAuth.email) {
        try {
          await reauthenticateUser();
          await verifyBeforeUpdateEmail(auth.currentUser, formData.email);
          setEmailVerificationSent(true);
          setSnackbar({
            open: true,
            message:
              'Verification email sent. Please check your new email and verify before the change takes effect.',
            color: 'primary'
          });
        } catch (error) {
          console.error('Error updating email:', error);
          setSnackbar({
            open: true,
            message: 'Error updating email. Please try again.',
            color: 'danger'
          });
          setProcessing(false);
          return;
        }
      }

      // Update Firestore document
      await updateDoc(doc(db, 'participants', userAuth.uid), {
        firstName: formData.firstName,
        lastName: formData.lastName,
        phoneNumber: formData.phoneNumber,
        personalNotes: formData.personalNotes
      });

      setSnackbar({
        open: true,
        message: 'Profile updated successfully',
        color: 'success'
      });
    } catch (error) {
      console.error('Error updating profile:', error);
      setSnackbar({
        open: true,
        message: 'Error updating profile',
        color: 'danger'
      });
    } finally {
      setProcessing(false);
    }
  };

  if (!user) {
    return <LinearProgress />;
  }

  const renderNotificationSection = () => {
    switch (notificationStatus) {
      case 'unsupported':
        return (
          <Alert color="warning" variant="soft" startDecorator={<Warning />}>
            {t('settings.noNotificationSupport')}
          </Alert>
        );
      case 'denied':
        return (
          <Alert color="warning" variant="soft" startDecorator={<Warning />}>
            {t('settings.notificationDenied')}
          </Alert>
        );
      case 'granted':
        return (
          <Alert color="success" variant="soft" startDecorator={<Check />}>
            {t('settings.notificationsEnabled')}
          </Alert>
        );
      case 'error':
        return (
          <Alert color="error" variant="soft" startDecorator={<Warning />}>
            {t('settings.errorRequestingPermission')}
          </Alert>
        );
      case 'prompt':
      default:
        return (
          <Alert
            color="warning"
            endDecorator={
              <Button
                onClick={requestPermission}
                size="sm"
                variant="solid"
                color="success"
                loading={isLoading}
              >
                {t('settings.enableNotifications')}
              </Button>
            }
          >
            {t('settings.pleaseEnableNotifications')}
          </Alert>
        );
    }
  };

  return (
    <Grid container sx={{ my: 8, mx: 3 }} spacing={3} justifyContent="center">
      <Grid xs={12}>
        <Typography level="h3" textAlign="center" gutterBottom>
          Settings
        </Typography>
        {emailVerificationSent && (
          <Alert color="info">
            A verification email has been sent to your new email address. Please
            verify it to complete the email change.
          </Alert>
        )}
      </Grid>
      <Grid xs={12} sm={6} lg={3}>
        <Stack spacing={3}>
          <form onSubmit={handleSubmit}>
            <Stack spacing={2}>
              <FormControl>
                <FormLabel>First name</FormLabel>
                <Input
                  name="firstName"
                  placeholder="First Name"
                  value={formData.firstName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Last name</FormLabel>
                <Input
                  name="lastName"
                  placeholder="Last Name"
                  value={formData.lastName}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Phone number</FormLabel>
                <Input
                  name="phoneNumber"
                  placeholder="Phone Number"
                  value={formData.phoneNumber}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Email</FormLabel>
                <Input
                  name="email"
                  placeholder="Email"
                  value={formData.email}
                  onChange={handleChange}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Personal notes</FormLabel>
                <Textarea
                  minRows={3}
                  name="personalNotes"
                  value={formData.personalNotes}
                  onChange={handleChange}
                  placeholder={t('settings.personalNotesPlaceholder')}
                />
              </FormControl>
              <Button
                type="submit"
                loading={processing}
                disabled={
                  formData.firstName === user.firstName &&
                  formData.lastName === user.lastName &&
                  formData.phoneNumber === user.phoneNumber &&
                  formData.email === userAuth.email &&
                  formData.personalNotes === user.personalNotes
                }
              >
                Update Profile
              </Button>
            </Stack>
          </form>
          <Snackbar
            open={snackbar.open}
            autoHideDuration={6000}
            onClose={() => setSnackbar({ ...snackbar, open: false })}
            color={snackbar.color}
          >
            {snackbar.message}
          </Snackbar>
        </Stack>
      </Grid>
      <Grid xs={12} sm={6} lg={3}>
        <Stack spacing={3}>
          {renderNotificationSection()}
          {/* <Alert
            color="info"
            endDecorator={
              <Select
                defaultValue={user.language || 'english'}
                onChange={(_, newValue) => handleLanguageChange(newValue)}
              >
                {languages.map(lang => (
                  <Option key={lang} value={lang}>
                    {upperFirstLetterWord(lang)}
                  </Option>
                ))}
              </Select>
            }
          >
            {t('settings.languagePreference')}
          </Alert> */}
        </Stack>
      </Grid>
    </Grid>
  );
};

export default Settings;
