import React from 'react';
import { Typography, Grid, Card, CardContent, AspectRatio } from '@mui/joy';
import {
  Info,
  YouTube,
  People,
  MedicalServices,
  Assignment
} from '@mui/icons-material';
import { useTranslation } from 'react-i18next';

const About = () => {
  const { t } = useTranslation();

  return (
    <Grid container justifyContent="center" sx={{ my: 8, mx: 3 }}>
      <Grid xs={12} sm={10} md={8} lg={6}>
        <Typography level="h2" gutterBottom startDecorator={<Info />}>
          {t('about.title')}
        </Typography>
        <Card variant="outlined" sx={{ mb: 4 }}>
          <CardContent>
            <Typography level="h3" gutterBottom startDecorator={<YouTube />}>
              {t('about.introTitle')}
            </Typography>
            <AspectRatio ratio="16/9" sx={{ mb: 2 }}>
              <iframe
                src="https://www.youtube.com/embed/WN21qNLXfrw?si=nYpbsp2owyq8XTAp"
                title={t('about.videoTitle')}
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                allowFullScreen
              />
            </AspectRatio>
            <Typography level="body-md">
              {t('about.videoDescription')}
            </Typography>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ mb: 4 }}>
          <CardContent>
            <Typography
              level="h3"
              gutterBottom
              startDecorator={<MedicalServices />}
            >
              {t('about.studyTitle')}
            </Typography>
            <Typography level="body-md" sx={{ mb: 2 }}>
              {t('about.studyDescription')}
            </Typography>
            <Typography level="body-md" sx={{ mb: 2 }}>
              {t('about.keyAspectsTitle')}
            </Typography>
            <ul>
              <li>{t('about.keyAspect1')}</li>
              <li>{t('about.keyAspect2')}</li>
              <li>{t('about.keyAspect3')}</li>
            </ul>
          </CardContent>
        </Card>
        <Card variant="outlined" sx={{ mb: 4 }}>
          <CardContent>
            <Typography level="h3" gutterBottom startDecorator={<Assignment />}>
              {t('about.importanceTitle')}
            </Typography>
            <Typography level="body-md" sx={{ mb: 2 }}>
              {t('about.importanceDescription')}
            </Typography>
            <ul>
              <li>{t('about.importancePoint1')}</li>
              <li>{t('about.importancePoint2')}</li>
              <li>{t('about.importancePoint3')}</li>
            </ul>
          </CardContent>
        </Card>
        <Card variant="outlined">
          <CardContent>
            <Typography level="h3" gutterBottom startDecorator={<People />}>
              {t('about.teamTitle')}
            </Typography>
            <Typography level="body-md" sx={{ mb: 2 }}>
              {t('about.teamDescription')}
            </Typography>
            <Typography level="body-md" sx={{ mb: 1 }}>
              {t('about.leadInvestigatorsTitle')}
            </Typography>
            <ul>
              <li>{t('about.investigator1')}</li>
              <li>{t('about.investigator2')}</li>
            </ul>
            <Typography level="body-md" sx={{ mt: 2 }}>
              {t('about.contactInfo')}
            </Typography>
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  );
};

export default About;
