import { Alert, Stack, Button, Box, Typography, Grid } from '@mui/joy';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

const ThankYou = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const message = location.state?.message || t('thankYou.thanks');
  return (
    <Grid container justifyContent="center" sx={{ my: 8, mx: 3 }}>
      <Grid xs={12} sm={9} md={6} lg={4}>
        <Stack spacing={3}>
          <Alert color="primary">{message}</Alert>
          <Alert
            variant="soft"
            color="neutral"
            endDecorator={
              <Button
                size="sm"
                variant="soft"
                color="primary"
                onClick={() => navigate('/dashboard/settings')}
              >
                {t('thankYou.addNotesButton')}
              </Button>
            }
          >
            {t('thankYou.addNotes')}
          </Alert>
          <Typography level="body-md" textAlign="center">
            {t('thankYou.canCloseWindow')}
          </Typography>
          <Typography level="body-md" textAlign="center" sx={{ mt: 2 }}>
            {t('thankYou.getInTouch')}
          </Typography>
          <Box display="flex" justifyContent="center">
            <Button onClick={() => navigate('/dashboard/contact')}>
              {t('thankYou.contact')}
            </Button>
          </Box>
        </Stack>
      </Grid>
    </Grid>
  );
};

export default ThankYou;
