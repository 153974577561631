import {
  FormControl,
  FormLabel,
  FormHelperText,
  Input,
  Select,
  Option,
  ToggleButtonGroup,
  Button,
  Alert,
  Checkbox,
  List,
  ListItem,
  Typography,
  Stack
} from '@mui/joy';
import { useTranslation } from 'react-i18next';
import { Field, FieldArray } from 'formik';
import Markdown from 'react-markdown';
import { format, parseISO, isValid } from 'date-fns';

const FlowchartNode = ({ node, previousResponse, currentValues }) => {
  const { t } = useTranslation();
  const { nodeId, type, helperTextWithData } = node;

  // Get translated content
  const translatedNode = t(`nodes.${nodeId}`, { returnObjects: true });

  const {
    text,
    helperText,
    heading,
    options: translatedOptions,
    helperTextWithData: helperTextWithDataLng
  } = translatedNode;

  // Combine original options (with conditions) with translated text only for relevant node types
  const options =
    [
      'enum',
      'enum_with_freetext',
      'conditional_options',
      'multi-select',
      'multi-select_with_freetext'
    ].includes(type) && node.options
      ? node.options.map(originalOption => {
          const translatedOption = translatedOptions?.find(
            o => o.key === originalOption.key
          );
          return {
            ...originalOption,
            text: translatedOption ? translatedOption.text : originalOption.text
          };
        })
      : translatedOptions; // Fallback to translated options if no original options or for other node types

  if (
    type === 'conditional' ||
    nodeId.startsWith('survey_complete') ||
    type === 'sub-graph'
  ) {
    return null;
  }

  if (type === 'text') {
    return (
      <Alert color="primary" sx={{ my: 3 }}>
        <Stack>
          <Typography level="title-lg" gutterBottom>
            {heading}
          </Typography>
          {text}
        </Stack>
      </Alert>
    );
  }

  let helperTextData;
  if (previousResponse && helperTextWithData) {
    const value = previousResponse[helperTextWithData.field];
    if (value) {
      let formattedValue = value;
      if (typeof value === 'string') {
        const date = parseISO(value);
        if (isValid(date)) {
          formattedValue = format(date, 'do MMMM yyyy');
        } else {
          // Check if the current node or the referenced node has options
          const nodeWithOptions = options
            ? translatedNode
            : t(`nodes.${helperTextWithData.field}`, { returnObjects: true });
          if (nodeWithOptions.options) {
            const option = nodeWithOptions.options.find(
              opt => opt.key === value
            );
            if (option) {
              formattedValue = option.text;
            }
          }
        }
      }

      helperTextData = `${helperTextWithDataLng.text} ${formattedValue}`;
    }
  }

  const evaluateCondition = (condition, answers) => {
    if (!condition) return true;

    if (condition.logic) {
      // This is a complex condition
      const results = condition.conditions.map(cond =>
        evaluateCondition(cond, answers)
      );
      const result =
        condition.logic === 'and'
          ? results.every(Boolean)
          : results.some(Boolean);
      return result;
    }

    // This is a simple condition
    const value = answers[condition.field];

    let result;
    switch (condition.operator) {
      case 'equals':
        result = value === condition.value;
        break;
      case 'not_equals':
        result = value !== condition.value;
        break;
      // Add other operators as needed
      default:
        result = true;
    }
    return result;
  };

  const filterOptions = (options, previousAnswers, currentAnswers) => {
    const combinedAnswers = { ...previousAnswers, ...currentAnswers };

    return options.filter(option => {
      if (!option.condition) return true;
      const result = evaluateCondition(option.condition, combinedAnswers);
      return result;
    });
  };

  const renderOptions = options => {
    const filteredOptions =
      type === 'conditional_options'
        ? filterOptions(options, previousResponse, currentValues)
        : options;

    return filteredOptions.map((option, index) => (
      <Option key={index} value={option.key}>
        {option.text}
      </Option>
    ));
  };

  return (
    <>
      {type === 'multi-select' || type === 'multi-select_with_freetext' ? (
        <FieldArray name={nodeId}>
          {({ form, push, remove }) => {
            const handleCheckboxChange = (optionKey, isChecked) => {
              if (optionKey === 'none_of_the_above' && isChecked) {
                form.setFieldValue(nodeId, ['none_of_the_above']);
              } else if (isChecked) {
                if (form.values[nodeId]?.includes('none_of_the_above')) {
                  form.setFieldValue(
                    nodeId,
                    form.values[nodeId].filter(
                      val => val !== 'none_of_the_above'
                    )
                  );
                }
                push(optionKey);
              } else {
                const idx = form.values[nodeId].indexOf(optionKey);
                remove(idx);
              }
            };

            return (
              <FormControl>
                <FormLabel>
                  <Markdown>{text}</Markdown>
                </FormLabel>
                <List size="sm">
                  {renderOptions(options).map((option, index) => (
                    <ListItem key={index}>
                      <Field name={`${nodeId}[${index}]`}>
                        {({ field }) => (
                          <Checkbox
                            name={option.props.value}
                            label={option.props.children}
                            checked={
                              form.values[nodeId]?.includes(
                                option.props.value
                              ) || false
                            }
                            onChange={e => {
                              handleCheckboxChange(
                                option.props.value,
                                e.target.checked
                              );
                            }}
                          />
                        )}
                      </Field>
                    </ListItem>
                  ))}
                </List>
                {type === 'multi-select_with_freetext' && (
                  <Field name={`${nodeId}_other`}>
                    {({ field, form }) =>
                      form.values[nodeId]?.includes(
                        'other_(please_specify)'
                      ) && (
                        <FormControl sx={{ mt: 1 }}>
                          <Input
                            {...field}
                            placeholder={t('validation.pleaseSpecify')}
                            error={
                              form.touched[`${nodeId}_other`] &&
                              Boolean(form.errors[`${nodeId}_other`])
                            }
                          />
                        </FormControl>
                      )
                    }
                  </Field>
                )}
                {helperText && (
                  <FormHelperText sx={{ color: 'neutral.plainColor' }}>
                    {helperText}
                  </FormHelperText>
                )}
                {helperTextData && (
                  <FormHelperText sx={{ color: 'neutral.plainColor' }}>
                    {helperTextData}
                  </FormHelperText>
                )}
              </FormControl>
            );
          }}
        </FieldArray>
      ) : (
        <>
          <Field name={nodeId}>
            {({ field, form }) => (
              <FormControl>
                <FormLabel>
                  <Markdown>{text}</Markdown>
                </FormLabel>
                {type === 'enum_with_freetext' ||
                type === 'enum' ||
                type === 'conditional_options' ? (
                  <Select
                    {...field}
                    slotProps={{
                      listbox: {
                        placement: 'bottom-start',
                        sx: { minWidth: 11 }
                      }
                    }}
                    value={field.value || ''}
                    onChange={(e, newValue) => {
                      form.setFieldValue(nodeId, newValue);
                    }}
                  >
                    {renderOptions(options)}
                  </Select>
                ) : type === 'boolean' ? (
                  <ToggleButtonGroup
                    color="primary"
                    variant="soft"
                    spacing={1}
                    value={field.value?.toString()}
                    onChange={(event, newValue) =>
                      form.setFieldValue(nodeId, newValue === 'true')
                    }
                  >
                    <Button value="true">{t('ui.yes')}</Button>
                    <Button value="false">{t('ui.no')}</Button>
                  </ToggleButtonGroup>
                ) : (
                  <Input
                    {...field}
                    value={field.value || ''}
                    type={
                      type === 'integer'
                        ? 'number'
                        : type === 'date'
                          ? 'date'
                          : 'text'
                    }
                    error={form.touched[nodeId] && Boolean(form.errors[nodeId])}
                  />
                )}
                {helperText && (
                  <FormHelperText sx={{ color: 'neutral.plainColor' }}>
                    {helperText}
                  </FormHelperText>
                )}
                {helperTextData && (
                  <FormHelperText sx={{ color: 'neutral.plainColor' }}>
                    {helperTextData}
                  </FormHelperText>
                )}
              </FormControl>
            )}
          </Field>
          {type === 'enum_with_freetext' && (
            <Field name={`${nodeId}_other`}>
              {({ field, form }) =>
                form.values[nodeId] === 'other_(please_specify)' && (
                  <FormControl sx={{ mt: 1 }}>
                    <Input
                      {...field}
                      placeholder={t('validation.pleaseSpecify')}
                      error={
                        form.touched[`${nodeId}_other`] &&
                        Boolean(form.errors[`${nodeId}_other`])
                      }
                    />
                  </FormControl>
                )
              }
            </Field>
          )}
        </>
      )}
    </>
  );
};

export default FlowchartNode;
