import { useState } from 'react';
import { Email, MarkEmailRead } from '@mui/icons-material';
import { Button, Typography, Textarea, Alert, Grid } from '@mui/joy';
import { getFunctions, httpsCallable } from 'firebase/functions';
import { useUser } from '../context/user';
import { useTranslation } from 'react-i18next';

const Contact = () => {
  const { t } = useTranslation();
  const [emailContent, setEmailContent] = useState('');
  const [emailSent, setEmailSent] = useState(false);
  const [sendingEmail, setSendingEmail] = useState(false);
  const { user } = useUser();

  let dashboardContent;
  if (emailSent) {
    dashboardContent = (
      <Alert color="success" sx={{ mt: 8 }}>
        <Typography level="body-md" startDecorator={<MarkEmailRead />}>
          {t('contact.thankYouMessage', { firstName: user.firstName })}
        </Typography>
      </Alert>
    );
  } else {
    dashboardContent = (
      <>
        <Typography level="body-lg" gutterBottom>
          {t('contact.promptMessage')}
        </Typography>
        <Textarea
          value={emailContent}
          size="lg"
          name="emailContent"
          sx={{ my: 8 }}
          onChange={evt => setEmailContent(evt.target.value)}
        />
        <Button
          disabled={emailContent.length === 0}
          loading={sendingEmail}
          onClick={async () => {
            setSendingEmail(true);
            const sendEmail = httpsCallable(getFunctions(), 'email');
            await sendEmail({
              to: 'info@elfs.org.au',
              subject: 'ELFS Additional Support Request',
              body: `<p>Hi team,</p>
                     <p>You received a support request email from ${user.firstName} ${user.lastName}.<br/>
                     Email: ${user.email}<br/>
                     Study ID: ${user.redcapId}<br/>
                     Phone number: ${user.phoneNumber}</p>
                     <p>--------<br/><pre>${emailContent}</pre><br/>--------</p>
                     <p>The ELFS app</p>`
            });
            setSendingEmail(false);
            setEmailSent(true);
          }}
        >
          {t('contact.submitButton')}
        </Button>
      </>
    );
  }

  return (
    <Grid container justifyContent="center" sx={{ my: 8, mx: 3 }}>
      <Grid xs={12} sm={9} md={6}>
        <Typography level="h3" gutterBottom startDecorator={<Email />}>
          {t('contact.title')}
        </Typography>
        {dashboardContent}
      </Grid>
    </Grid>
  );
};

export default Contact;
