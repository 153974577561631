import { getDocs, getFirestore, collection } from 'firebase/firestore';
import {
  parseISO,
  differenceInYears,
  differenceInMonths,
  isValid
} from 'date-fns';
import * as Yup from 'yup';

const validationSchema = (nodes, t) => {
  return Yup.object().shape(
    nodes.reduce((schema, node) => {
      if (node.type === 'integer') {
        schema[node.nodeId] = Yup.number()
          .integer(t('validation.mustBeInteger'))
          .min(1, t('validation.mustBeGreaterThanZero'))
          .required(t('validation.numberRequired'));
      } else if (node.type === 'string') {
        schema[node.nodeId] = node.isOptional
          ? Yup.string().notRequired()
          : (schema[node.nodeId] = Yup.string().required(
              t('validation.fieldRequired')
            ));
      } else if (node.type === 'boolean') {
        schema[node.nodeId] = Yup.string().required(
          t('validation.choiceRequired')
        );
      } else if (node.type === 'date') {
        schema[node.nodeId] = Yup.date().required(t('validation.dateRequired'));
      } else if (node.type === 'enum') {
        schema[node.nodeId] = Yup.string().required(
          t('validation.selectionRequired')
        );
      } else if (node.type === 'enum_with_freetext') {
        schema[node.nodeId] = Yup.string().required(
          t('validation.selectionRequired')
        );
        schema[`${node.nodeId}_other`] = Yup.string().test(
          'is-specified',
          t('validation.pleaseSpecify'),
          function (value) {
            const { parent } = this;
            return parent[node.nodeId] !== 'Other (please specify)' || !!value;
          }
        );
      } else if (node.type === 'multi-select') {
        schema[node.nodeId] = Yup.array().required(
          t('validation.atLeastOneOption')
        );
      } else if (node.type === 'multi-select_with_freetext') {
        schema[node.nodeId] = Yup.array().required(
          t('validation.atLeastOneOption')
        );
        schema[`${node.nodeId}_other`] = Yup.string().test(
          'is-specified',
          t('validation.pleaseSpecify'),
          function (value) {
            const { parent } = this;
            return (
              !parent[node.nodeId]?.includes('Other (please specify)') ||
              !!value
            );
          }
        );
      }
      return schema;
    }, {})
  );
};

// Determine the next starting node based on the user's responses
const getNextStartingNode = (nodes, lastNodeId, userResponses) => {
  const lastNode = nodes[lastNodeId];

  if (!lastNode) {
    return null;
  }

  if (lastNode.type === 'boolean') {
    const responseValue = userResponses[lastNode.nodeId];
    return lastNode.next[responseValue];
  }

  if (
    lastNode.type.startsWith('enum') ||
    lastNode.type === 'conditional_options'
  ) {
    const userResponse = userResponses[lastNode.nodeId];
    return typeof lastNode.next === 'object' && lastNode.next[userResponse]
      ? lastNode.next[userResponse]
      : lastNode.next;
  }

  return lastNode.next || null;
};

// Get the nodes to render based on the starting node and previous responses
const nodeStack = [];
const getNextNodesToRender = (
  nodes,
  startingNodeId,
  previousResponse = {},
  currentResponse = {}
) => {
  console.log('\n--- Starting node traversal ---');
  console.log(`Starting with node: ${startingNodeId}`);
  let nodesToRender = [];
  let currentNodeId = startingNodeId;

  const evaluateCondition = condition => {
    const {
      field,
      fieldType,
      operator,
      value,
      useCurrentResponse,
      valueUnits
    } = condition;

    let responseValue =
      useCurrentResponse && currentResponse[field] !== undefined
        ? currentResponse[field]
        : previousResponse[field];

    console.log(`\nEvaluating condition for field: ${field}`);
    console.log(`Operator: ${operator}`);
    console.log(`Expected value: ${value}`);
    console.log(`Actual value: ${responseValue}`);

    if (operator === 'exists') {
      const result = responseValue !== undefined && responseValue !== null;
      console.log(`Result: ${result ? 'Exists' : 'Does not exist'}`);
      return result;
    } else if (operator === 'equals') {
      const result = responseValue === value;
      console.log(`Result: ${result ? 'Equal' : 'Not equal'}`);
      return result;
    } else if (operator === 'greater_or_equal_than') {
      if (fieldType === 'date') {
        if (!responseValue) {
          console.log('Result: False (responseValue is undefined or null)');
          return false;
        }

        const parsedDate = parseISO(responseValue);

        if (!isValid(parsedDate)) {
          console.log('Result: False (Invalid date)');
          return false;
        }

        if (valueUnits === 'years') {
          const diffInYears = differenceInYears(new Date(), parsedDate);
          const result = diffInYears >= value;
          console.log(`Difference in years: ${diffInYears}`);
          console.log(`Result: ${result ? 'Greater or equal' : 'Less than'}`);
          return result;
        } else if (valueUnits === 'months') {
          const diffInMonths = differenceInMonths(new Date(), parsedDate);
          const result = diffInMonths >= value;
          console.log(`Difference in months: ${diffInMonths}`);
          console.log(`Result: ${result ? 'Greater or equal' : 'Less than'}`);
          return result;
        }
      } else {
        const result = parseFloat(responseValue) >= parseFloat(value);
        console.log(`Result: ${result ? 'Greater or equal' : 'Less than'}`);
        return result;
      }
    }
    console.log('Result: False (Unhandled condition)');
    return false;
  };

  const evaluateComplexCondition = (conditions, operator) => {
    console.log(`\nEvaluating complex condition with ${operator} operator`);
    if (operator === 'and') {
      const result = conditions.every(condition =>
        evaluateCondition(condition)
      );
      console.log(
        `Complex condition result: ${result ? 'True' : 'False'} (All conditions met)`
      );
      return result;
    } else if (operator === 'or') {
      const result = conditions.some(condition => evaluateCondition(condition));
      console.log(
        `Complex condition result: ${result ? 'True' : 'False'} (At least one condition met)`
      );
      return result;
    }
    console.log('Complex condition result: False (Unhandled operator)');
    return false;
  };

  const findNextNode = currentNodeId => {
    console.log(`\nProcessing node: ${currentNodeId}`);
    const currentNode = nodes[currentNodeId];

    if (currentNodeId === 'return') {
      const nextNode = nodeStack.pop();
      console.log(`Returning to previous node: ${nextNode}`);
      return nextNode;
    }

    if (!currentNode) {
      console.log('Node not found. Ending traversal.');
      return null;
    }

    nodesToRender.push(currentNode);
    console.log(`Added node ${currentNodeId} to render list`);

    if (currentNode.isBlocking) {
      console.log('Blocking node encountered. Stopping traversal.');
      return null;
    }

    if (currentNode.type === 'conditional') {
      console.log('Processing conditional node');
      const {
        operator,
        conditions,
        trueNext,
        falseNext,
        field,
        value,
        useCurrentResponse,
        fieldType,
        valueUnits
      } = currentNode.condition;

      let result;
      if (operator === 'and' || operator === 'or') {
        result = evaluateComplexCondition(conditions, operator);
      } else if (
        operator === 'exists' ||
        operator === 'equals' ||
        operator === 'greater_or_equal_than'
      ) {
        result = evaluateCondition({
          field,
          fieldType,
          operator,
          value,
          useCurrentResponse,
          valueUnits
        });
      }

      const nextNode = result ? trueNext : falseNext;
      console.log(
        `Condition ${result ? 'met' : 'not met'}. Moving to node: ${nextNode}`
      );
      return nextNode;
    } else if (currentNode.type === 'sub-graph') {
      console.log(
        `Entering sub-graph. Pushing ${currentNode.returnNext} to stack`
      );
      nodeStack.push(currentNode.returnNext);
      return currentNode.next;
    } else if (typeof currentNode.next === 'string') {
      if (currentNode.next === 'return') {
        const nextNode = nodeStack.pop();
        console.log(`Returning from sub-graph to node: ${nextNode}`);
        return nextNode || null;
      } else {
        console.log(`Moving to next node: ${currentNode.next}`);
        return currentNode.next || null;
      }
    } else {
      console.log('No clear next node. Stopping traversal.');
      return null;
    }
  };

  while (currentNodeId) {
    const startNodeId = currentNodeId;
    currentNodeId = findNextNode(currentNodeId);
    if (currentNodeId) {
      console.log(`Transition: ${startNodeId} => ${currentNodeId}`);
    } else {
      console.log('--- Ending node traversal ---\n');
    }
  }

  return nodesToRender;
};

// Fetch all nodes from Firestore
const getAllNodes = async () => {
  const nodesSnapshot = await getDocs(collection(getFirestore(), 'nodes'));
  const nodes = {};
  nodesSnapshot.forEach(doc => {
    nodes[doc.data().nodeId] = doc.data();
  });
  return nodes;
};

const initialValues = (nodes, previousResponse = {}) =>
  nodes.reduce((acc, node) => {
    if (!['conditional', 'text', 'sub-graph'].includes(node.type)) {
      if (node.prefill && previousResponse[node.nodeId] != null) {
        acc[node.nodeId] = previousResponse[node.nodeId];
      } else {
        acc[node.nodeId] = '';
      }
      if (
        node.type === 'enum_with_freetext' ||
        node.type === 'multi-select_with_freetext'
      ) {
        acc[`${node.nodeId}_other`] = '';
      }
    }
    return acc;
  }, {});

export {
  validationSchema,
  initialValues,
  getAllNodes,
  getNextNodesToRender,
  getNextStartingNode
};
