import { initializeApp } from 'firebase/app';
import { getMessaging } from 'firebase/messaging';

const firebaseConfig = {
  apiKey: 'AIzaSyAQc0gtLQ6HQHBJqJ_cN0zFjJeFGz11EEM',
  authDomain: 'elfs-research.firebaseapp.com',
  projectId: 'elfs-research',
  storageBucket: 'elfs-research.appspot.com',
  messagingSenderId: '1042040779262',
  appId: '1:1042040779262:web:883b122f1bee6771fc10af',
  measurementId: 'G-S0LT1V3P24'
};

const app = initializeApp(firebaseConfig);
getMessaging(app);
