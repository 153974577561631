import {
  AspectRatio,
  Typography,
  Button,
  Grid,
  Stack,
  Input,
  FormLabel,
  FormControl,
  Alert,
  Box,
  Link,
  LinearProgress,
  Snackbar,
  IconButton
} from '@mui/joy';
import { useColorScheme } from '@mui/joy/styles';
import CloseIcon from '@mui/icons-material/Close';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { useState, useRef } from 'react';
import { doc, getFirestore, getDoc } from 'firebase/firestore';
import {
  signInWithEmailAndPassword,
  getAuth,
  sendPasswordResetEmail
} from 'firebase/auth';
import { useUser } from '../context/user';
import logoLight from '../images/ELFS-logo-light-mode.png';
import logoDark from '../images/ELFS-logo-dark-mode.png';

const LandingPage = () => {
  const { loading } = useUser();
  const { mode, systemMode } = useColorScheme();
  const [error, setError] = useState(null);
  const [processingAuth, setProcessingAuth] = useState(false);
  const [isResettingPassword, setIsResettingPassword] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const emailInputRef = useRef(null);

  const logoMode = mode === 'system' ? systemMode : mode;
  const logo = logoMode === 'dark' ? logoDark : logoLight;

  const handleLogin = async event => {
    event.preventDefault();
    setProcessingAuth(true);
    setError(null);
    try {
      const userCredential = await signInWithEmailAndPassword(
        getAuth(),
        email,
        password
      );
      const user = userCredential.user;
      const userDocSnapshot = await getDoc(
        doc(getFirestore(), 'participants', user.uid)
      );

      if (!userDocSnapshot.exists()) {
        setError(
          'You are not a registered participant. Please contact support.'
        );
      }
    } catch (error) {
      console.error('Login error:', error);
      switch (error.code) {
        case 'auth/invalid-credential':
          setError(
            'Invalid email or password. Please check your credentials and try again.'
          );
          break;
        case 'auth/user-disabled':
          setError('This account has been disabled. Please contact support.');
          break;
        case 'auth/too-many-requests':
          setError(
            'Too many failed login attempts. Please try again later or reset your password.'
          );
          break;
        case 'auth/network-request-failed':
          setError(
            'Network error. Please check your internet connection and try again.'
          );
          break;
        default:
          setError(
            'An unexpected error occurred. Please try again or contact support if the problem persists.'
          );
      }
    }
    setProcessingAuth(false);
  };

  const handlePasswordReset = async event => {
    event.preventDefault();
    if (!email) {
      setError('Please enter your email address to reset your password.');
      emailInputRef.current.focus();
      return;
    }
    setIsResettingPassword(true);
    try {
      await sendPasswordResetEmail(getAuth(), email);
      setSnackbarOpen(true);
      setError(null);
    } catch (error) {
      console.error('Error sending password reset email:', error);
      if (error.code === 'auth/invalid-email') {
        setError('The email address is not valid.');
      } else if (error.code === 'auth/user-disabled') {
        setError('This user account has been disabled.');
      } else if (error.code === 'auth/too-many-requests') {
        setError('Too many requests. Please try again later.');
      } else {
        setError('Failed to send password reset email. Please try again.');
      }
    }
    setIsResettingPassword(false);
  };

  const handleSnackbarClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbarOpen(false);
  };

  if (loading) {
    return <LinearProgress />;
  }

  return (
    <>
      <Grid
        container
        alignItems="center"
        sx={{
          minHeight: '88vh',
          my: 1,
          mx: 3
        }}
        spacing={9}
        justifyContent="center"
      >
        <Grid xs={12} sm={6} lg={4} xl={3}>
          <AspectRatio variant="plain" ratio="1">
            <img src={logo} alt="ELFS" />
          </AspectRatio>
        </Grid>
        <Grid xs={12} sm={6} lg={5} xl={5}>
          <Stack gap={3}>
            <Box>
              <Typography level="h1" gutterBottom>
                Welcome to ELFS
              </Typography>
              <Typography level="body-lg">
                Thank you for joining us on this important journey. Your
                participation in our research is deeply valued and essential.
              </Typography>
            </Box>
            {error && <Alert color="danger">{error}</Alert>}
            <Stack
              component="form"
              spacing={2}
              noValidate
              autoComplete="off"
              onSubmit={handleLogin}
            >
              <FormControl>
                <FormLabel>Email address</FormLabel>
                <Input
                  required
                  type="email"
                  autoComplete="email"
                  onChange={e => setEmail(e.target.value)}
                  value={email}
                  ref={emailInputRef}
                />
              </FormControl>
              <FormControl>
                <FormLabel>Password</FormLabel>
                <Input
                  required
                  type="password"
                  autoComplete="current-password"
                  onChange={e => setPassword(e.target.value)}
                  value={password}
                />
              </FormControl>

              <Button
                type="submit"
                size="lg"
                loading={processingAuth}
                disabled={!email || !password}
              >
                Continue to Your Dashboard
              </Button>
            </Stack>
            <Stack
              direction={{ xs: 'column', sm: 'row' }}
              justifyContent="space-between"
            >
              <Typography
                level="body-sm"
                gutterBottom
                endDecorator={
                  <Link
                    component="button"
                    onClick={handlePasswordReset}
                    disabled={isResettingPassword}
                    level="body-sm"
                  >
                    Reset here
                  </Link>
                }
              >
                Forgot password?
              </Typography>
              <Typography
                level="body-sm"
                endDecorator={
                  <Link href="mailto:support@golightlyplus.com" level="body-sm">
                    Contact Support
                  </Link>
                }
              >
                Need assistance?
              </Typography>
            </Stack>
          </Stack>
        </Grid>
      </Grid>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={handleSnackbarClose}
        color="success"
        size="sm"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        startDecorator={<CheckCircleIcon />}
        endDecorator={
          <IconButton
            onClick={handleSnackbarClose}
            size="sm"
            variant="plain"
            color="neutral"
          >
            <CloseIcon />
          </IconButton>
        }
      >
        If an account exists for {email}, a password reset email will be sent
        shortly.
      </Snackbar>
    </>
  );
};

export default LandingPage;
