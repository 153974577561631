import { extendTheme } from '@mui/joy/styles';

// Design tokens
export const designTokens = {
  // Main colors
  primary: '#E46399', // China Pink
  secondary: '#C398C5', // Lilac
  tertiary: '#DF9843', // Carrot Orange
  quaternary: '#2A2E45', // Space Cadet

  // Background colors
  background: {
    light: '#E7EDF7', // Alice Blue
    dark: '#2A2E45' // Space Cadet
  },

  // Text colors
  text: {
    light: '#2A2E45', // Space Cadet
    dark: '#FFFFFF', // White
    lightSecondary: '#5C5C5C', // Davys Grey
    darkSecondary: '#AEADAE' // Silver Chalice
  },

  // Button colors
  button: {
    default: '#E46399', // China Pink
    selected: '#E46399', // China Pink
    unselected: '#F2C9DF', // Orchid Pink (lighter version of China Pink)
    hover: '#C398C5', // Lilac
    active: '#DF9843' // Carrot Orange
  },

  // Additional colors
  white: '#FFFFFF',
  black: '#000000',
  orchidPink: '#F2C9DF',
  champagnePink: '#E5D4CE',
  lavenderWeb: '#D9E0F2',

  // Grayscale
  gray: {
    light: '#AEADAE', // Silver Chalice
    medium: '#939393', // Spanish Grey
    dark: '#5C5C5C' // Davys Grey
  }
};

const createTheme = mode => ({
  fontFamily: {
    body: '-apple-system, Roboto, sans-serif'
  },
  typography: {
    'title-lg': {
      fontFamily: '-apple-system, Roboto, sans-serif',
      fontWeight: 600, // SF Pro Text Semibold/Roboto Bold
      fontSize: '1.5rem',
      color: mode === 'light' ? designTokens.text.light : designTokens.text.dark
    },
    'body-md': {
      fontFamily: '-apple-system, Roboto, sans-serif',
      fontWeight: 400, // SF Pro Text Regular/Roboto Regular
      color:
        mode === 'light'
          ? designTokens.text.lightSecondary
          : designTokens.text.darkSecondary
    },
    'body-sm': {
      fontFamily: '-apple-system, Roboto, sans-serif',
      fontWeight: 400,
      fontSize: '0.875rem',
      color:
        mode === 'light'
          ? designTokens.text.lightSecondary
          : designTokens.text.darkSecondary
    },
    button: {
      fontFamily: '-apple-system, Roboto, sans-serif',
      fontWeight: 600 // SF Pro Text Semibold/Roboto Bold
    }
  }
});

const createPalette = mode => ({
  primary: {
    main: designTokens.primary,
    solidBg: designTokens.button.default,
    solidHoverBg: designTokens.button.hover,
    solidActiveBg: designTokens.button.active,
    solidColor: designTokens.white,
    softBg: designTokens.button.unselected,
    softHoverBg: designTokens.button.hover,
    softActiveBg: designTokens.button.selected,
    softColor: designTokens.text.light,
    softActiveColor: designTokens.white,
    plainColor: designTokens.primary,
    plainHoverBg: designTokens.button.unselected,
    plainActiveBg: designTokens.button.hover
  },
  secondary: {
    main: designTokens.secondary
  },
  success: {
    main: designTokens.tertiary,
    solidBg: designTokens.tertiary,
    solidHoverBg: designTokens.button.hover,
    solidActiveBg: designTokens.button.active,
    solidColor: designTokens.white,
    softBg: designTokens.champagnePink,
    softColor: designTokens.quaternary
  },
  warning: {
    main: designTokens.tertiary,
    softBg: designTokens.orchidPink,
    softColor: designTokens.quaternary
  },
  error: {
    main: designTokens.primary,
    softBg: designTokens.orchidPink,
    softColor: designTokens.quaternary
  },
  info: {
    main: designTokens.secondary,
    softBg: designTokens.lavenderWeb,
    softColor: designTokens.quaternary,
    plainColor: designTokens.secondary,
    plainHoverBg: designTokens.lavenderWeb,
    plainActiveBg: designTokens.secondary
  },
  background: {
    body:
      mode === 'light'
        ? designTokens.background.light
        : designTokens.background.dark,
    surface: mode === 'light' ? designTokens.white : designTokens.quaternary,
    level1: mode === 'light' ? designTokens.white : designTokens.quaternary,
    level2:
      mode === 'light'
        ? designTokens.background.light
        : designTokens.background.dark,
    popup: mode === 'light' ? designTokens.white : designTokens.quaternary
  },
  text: {
    primary:
      mode === 'light' ? designTokens.text.light : designTokens.text.dark,
    secondary:
      mode === 'light'
        ? designTokens.text.lightSecondary
        : designTokens.text.darkSecondary
  },
  divider: mode === 'light' ? designTokens.gray.light : designTokens.gray.dark,
  mode: mode
});

export const baseTheme = extendTheme({
  cssVarPrefix: 'elfs',
  colorSchemes: {
    light: {
      palette: createPalette('light'),
      ...createTheme('light')
    },
    dark: {
      palette: createPalette('dark'),
      ...createTheme('dark')
    }
  }
});

// Direction-specific themes
export const ltrTheme = extendTheme({
  ...baseTheme,
  direction: 'ltr'
});

export const rtlTheme = extendTheme({
  ...baseTheme,
  direction: 'rtl'
});
