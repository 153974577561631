import { StrictMode, useEffect } from 'react';
import { createRoot } from 'react-dom/client';
import { CssVarsProvider } from '@mui/joy/styles';
import { CssBaseline } from '@mui/joy';
import {
  createBrowserRouter,
  RouterProvider,
  Outlet,
  Navigate
} from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import { prefixer } from 'stylis';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
import createCache from '@emotion/cache';
import englishTranslations from './locales/english.json';
import vietnameseTranslations from './locales/vietnamese.json';
import hebrewTranslations from './locales/hebrew.json';
import './modules/firebase';
import LandingPage from './components/LandingPage';
import Dashboard from './components/Dashboard';
import Home from './components/Home';
import ThankYou from './components/ThankYou';
// import Debug from './components/Debug';
import Settings from './components/Settings';
import ErrorBoundary from './components/ErrorBoundary';
import { UserProvider, useUser } from './context/user';
import Contact from './components/Contact';
import SurveyHistory from './components/SurveyHistory';
import About from './components/About';
import { ltrTheme, rtlTheme } from './theme';

i18n.use(initReactI18next).init({
  resources: {
    english: { translation: englishTranslations },
    vietnamese: { translation: vietnameseTranslations },
    hebrew: { translation: hebrewTranslations }
  },
  lng: 'english', // Set default language
  fallbackLng: 'english',
  interpolation: {
    escapeValue: false
  }
});

// Create rtl cache
const cacheRtl = createCache({
  key: 'muirtl',
  stylisPlugins: [prefixer, rtlPlugin]
});

// Create ltr cache
const cacheLtr = createCache({
  key: 'muiltr'
});

const App = () => {
  return (
    <UserProvider>
      <AppContent />
    </UserProvider>
  );
};

const AppContent = () => {
  const { user } = useUser();
  const isRtl = user?.language === 'hebrew';

  useEffect(() => {
    document.dir = isRtl ? 'rtl' : 'ltr';
  }, [isRtl]);

  return (
    <CacheProvider value={isRtl ? cacheRtl : cacheLtr}>
      <CssVarsProvider theme={isRtl ? rtlTheme : ltrTheme} defaultMode="system">
        <CssBaseline />
        <Outlet />
      </CssVarsProvider>
    </CacheProvider>
  );
};

const router = createBrowserRouter([
  {
    element: <App />,
    errorElement: <ErrorBoundary />,
    children: [
      {
        path: '/',
        element: <LandingPage />
      },
      {
        path: '/dashboard',
        element: <Dashboard />,
        children: [
          { index: true, element: <Navigate to="home" replace /> },
          {
            path: 'home',
            element: <Home />
          },
          {
            path: 'history',
            element: <SurveyHistory />
          },
          {
            path: 'about',
            element: <About />
          },
          {
            path: 'settings',
            element: <Settings />
          },
          // {
          //   path: 'debug',
          //   element: <Debug />
          // },
          {
            path: 'thank-you',
            element: <ThankYou />
          },
          {
            path: 'contact',
            element: <Contact />
          }
        ]
      }
    ]
  }
]);

createRoot(document.getElementById('root')).render(
  <StrictMode>
    <RouterProvider router={router} />
  </StrictMode>
);
