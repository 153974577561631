const upperFirstLetterWord = str => {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

const cleanFormValues = values => {
  return Object.entries(values).reduce((acc, [key, value]) => {
    if (key.endsWith('_other') && value === '') {
      return acc; // Skip this key-value pair
    }
    acc[key] = value; // Keep all other key-value pairs
    return acc;
  }, {});
};

export { upperFirstLetterWord, cleanFormValues };
