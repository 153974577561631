import { Email, Home, Warning } from '@mui/icons-material';
import {
  Button,
  Alert,
  Stack,
  Grid,
  Typography,
  Card,
  CardContent,
  Divider,
  CssVarsProvider
} from '@mui/joy';
import {
  useRouteError,
  useNavigate,
  isRouteErrorResponse
} from 'react-router-dom';
import { baseTheme } from '../theme';

const ErrorBoundary = () => {
  const error = useRouteError();
  const navigate = useNavigate();

  const getErrorMessage = () => {
    if (isRouteErrorResponse(error)) {
      switch (error.status) {
        case 404:
          return "The page you're looking for doesn't exist.";
        case 401:
          return "You aren't authorised to access this page.";
        case 503:
          return 'Our service is temporarily unavailable.';
        default:
          return `Something went wrong (error ${error.status}).`;
      }
    }
    return error?.message || 'An unexpected error occurred.';
  };

  return (
    <CssVarsProvider theme={baseTheme} defaultMode="system">
      <Grid container justifyContent="center" sx={{ mt: 9 }}>
        <Grid xs={11} sm={8} lg={4}>
          <Card variant="outlined">
            <CardContent>
              <Stack spacing={3}>
                <Stack direction="row" spacing={1} alignItems="center">
                  <Warning sx={{ color: 'primary.main', fontSize: '2rem' }} />
                  <Typography level="title-lg">
                    Oops! Something's not quite right...
                  </Typography>
                </Stack>

                <Alert variant="soft" color="primary" size="lg">
                  {getErrorMessage()}
                </Alert>

                <Divider />

                <Stack direction="row" spacing={2} justifyContent="center">
                  <Button
                    variant="solid"
                    color="primary"
                    startDecorator={<Home />}
                    onClick={() => navigate('/')}
                  >
                    Return Home
                  </Button>

                  <Button
                    component="a"
                    variant="soft"
                    color="neutral"
                    href={`mailto:support@golightlyplus.com?subject=ELFS%20app%20error&body=Error%20details:%20${encodeURIComponent(getErrorMessage())}`}
                    startDecorator={<Email />}
                  >
                    Contact Support
                  </Button>
                </Stack>

                <Typography level="body-sm" textAlign="center">
                  If this problem persists, please contact our support team for
                  assistance.
                </Typography>
              </Stack>
            </CardContent>
          </Card>
        </Grid>
      </Grid>
    </CssVarsProvider>
  );
};

export default ErrorBoundary;
