import {
  Button,
  Typography,
  Box,
  Stack,
  Divider,
  List,
  ListItemDecorator,
  ListItemButton,
  ListItem,
  Alert,
  Drawer,
  IconButton,
  ListDivider
} from '@mui/joy';
import { signOut, getAuth } from 'firebase/auth';
import {
  Settings,
  Menu,
  HelpOutline,
  History,
  Info,
  Assignment,
  Logout
} from '@mui/icons-material';
import { Outlet, useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { format, parseISO, differenceInYears } from 'date-fns';
import { useTranslation } from 'react-i18next';
import { useUser } from '../context/user';

const Dashboard = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const { user } = useUser();
  const [showDrawer, setShowDrawer] = useState(false);

  const handleLogout = async () => {
    try {
      await signOut(getAuth());
      navigate('/');
    } catch (error) {
      console.error('Error signing out:', error);
    }
  };

  const goToPage = page => {
    navigate(page);
    setShowDrawer(false);
  };

  return (
    <>
      <IconButton
        size="lg"
        onClick={() => setShowDrawer(true)}
        sx={{
          position: 'fixed',
          top: 8,
          left: 8,
          zIndex: 1111
        }}
      >
        <Menu />
      </IconButton>
      <Drawer open={showDrawer} onClose={() => setShowDrawer(false)}>
        <Box
          sx={{
            p: 2,
            display: 'flex',
            flexDirection: 'column',
            height: '100%'
          }}
        >
          <Typography level="h3" sx={{ mb: 1 }}>
            ELFS
          </Typography>

          <Typography level="body-md">
            {user?.firstName} {user?.lastName}
          </Typography>
          <Typography level="body-xs">{user?.email}</Typography>
          {user?.dob && (
            <Typography level="body-xs">
              {format(parseISO(user.dob), 'do MMMM yyyy')} (age{' '}
              {differenceInYears(new Date(), parseISO(user.dob))})
            </Typography>
          )}
          <Divider sx={{ my: 2 }} />

          <List sx={{ flexGrow: 1 }}>
            <ListItem>
              <ListItemButton onClick={() => goToPage('home')}>
                <ListItemDecorator>
                  <Assignment />
                </ListItemDecorator>
                {t('dashboard.survey')}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('history')}>
                <ListItemDecorator>
                  <History />
                </ListItemDecorator>
                {t('dashboard.surveyHistory')}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('about')}>
                <ListItemDecorator>
                  <Info />
                </ListItemDecorator>
                {t('dashboard.about')}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('settings')}>
                <ListItemDecorator>
                  <Settings />
                </ListItemDecorator>
                {t('dashboard.settings')}
              </ListItemButton>
            </ListItem>
            <ListItem>
              <ListItemButton onClick={() => goToPage('contact')}>
                <ListItemDecorator>
                  <HelpOutline />
                </ListItemDecorator>
                {t('dashboard.getSupport')}
              </ListItemButton>
            </ListItem>
            {/* <ListDivider />
            <ListItem>
              <ListItemButton onClick={() => goToPage('debug')}>
                <ListItemDecorator>
                  <BugReport />
                </ListItemDecorator>
                {t('dashboard.devDebug')}
              </ListItemButton>
            </ListItem> */}
            <ListDivider />
            <ListItem>
              <ListItemButton onClick={handleLogout}>
                <ListItemDecorator>
                  <Logout />
                </ListItemDecorator>
                {t('dashboard.logout')}
              </ListItemButton>
            </ListItem>
          </List>
          <Alert
            variant="soft"
            endDecorator={
              <Button
                size="sm"
                href="https://contact.golightlyplus.com"
                component="a"
                variant="plain"
                target="_blank"
                color="info"
              >
                {t('dashboard.contact')}
              </Button>
            }
          >
            <Stack>
              <Typography level="body-sm">
                {t('dashboard.studyName')}
              </Typography>
              <Typography level="body-xs">
                ({t('dashboard.version')}: {process.env.REACT_APP_VERSION})
              </Typography>
              <Typography level="body-xs">
                © Golightly+ {new Date().getFullYear()}
              </Typography>
            </Stack>
          </Alert>
        </Box>
      </Drawer>
      <Outlet />
    </>
  );
};

export default Dashboard;

/*
<Grid container justifyContent="center" sx={{ my: 8, mx: 3 }}>
  <Grid xs={12} sm={9} md={6}>
  </Grid>
</Grid>
*/
