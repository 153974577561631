import { createContext, useContext, useState, useEffect } from 'react';
import { onAuthStateChanged, getAuth, signOut } from 'firebase/auth';
import { doc, getFirestore, getDoc, onSnapshot } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import i18n from 'i18next';

const UserContext = createContext(null);

const useUser = () => {
  return useContext(UserContext);
};

const UserProvider = props => {
  const [userAuth, setUserAuth] = useState(null);
  const [loading, setLoading] = useState(true);
  const [user, setUser] = useState(null);
  const navigate = useNavigate();

  // Handle authentication state changes
  useEffect(() => {
    const unsubscribe = onAuthStateChanged(getAuth(), async user => {
      console.log('auth', user);
      if (user) {
        try {
          const userDocSnapshot = await getDoc(
            doc(getFirestore(), 'participants', user.uid)
          );
          // if this user is part of the participant collection, then login
          if (userDocSnapshot.exists()) {
            setUserAuth(user);
            console.log('nav to dashboard');
            navigate('/dashboard');
          } else {
            await signOut(getAuth());
            navigate('/');
          }
        } catch (error) {
          console.error('Error checking participant status:', error);
          await signOut(getAuth());
          navigate('/');
        }
      } else {
        console.log("We don't have a user...");
        setLoading(false);
        setUserAuth(null);
        setUser(null);
        navigate('/');
      }
    });

    return () => unsubscribe();
  }, [navigate]);

  // Listen for changes to the participant document
  useEffect(() => {
    let unsub;
    if (userAuth) {
      unsub = onSnapshot(
        doc(getFirestore(), 'participants', userAuth.uid),
        docSnapshot => {
          const userData = {
            ...docSnapshot.data(),
            id: docSnapshot.id,
            email: userAuth.email
          };
          setUser(userData);

          // Check if the loaded language is different from the current language
          if (userData.language && i18n.language !== userData.language) {
            i18n.changeLanguage(userData.language);
          }
        },
        error => {
          console.error('Error listening to participant document:', error);
          signOut(getAuth());
          navigate('/');
        }
      );
    }
    return () => unsub && unsub();
  }, [userAuth, navigate]);

  return (
    <UserContext.Provider value={{ user, loading, userAuth }}>
      {props.children}
    </UserContext.Provider>
  );
};

export { useUser, UserProvider };
